import { render, staticRenderFns } from "./userdetails.vue?vue&type=template&id=47c7968c&scoped=true&"
import script from "./userdetails.vue?vue&type=script&lang=js&"
export * from "./userdetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47c7968c",
  null
  
)

export default component.exports