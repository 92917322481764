<template>
  <div>
    <compser />
  </div>
</template>

<script>
import compser from "./compser.vue";
export default {
  name: "userdetails",
  components: { compser },
  data() {
    return {
      data: {},
    };
  },
};
</script>
<style lang='scss' scoped>
</style>